

const Legal = () => {

return (
    <div>
        To learn more about how Ramboll handles personal data, please see our <a href='https://ramboll.com/terms-of-use-and-privacy-policy/privacy-policy-digital-campaigns'>Privacy Policy </a>.
        Note the information provided within the first tab of the “Format for reporting monitoring data by downstream users of Authorisation decisions REACH/20/18/0 to REACH/20/18/34 and REACH/20/17/0 to REACH/20/17/3 [XLSM]” by <a href= "https://echa.europa.eu/support/dossier-submission-tools/reach-it/downstream-user-authorised-use"> (ECHA </a>, date: 2021-10-25) about conditions and your responsibilities when uploading the report and the data therein to REACH-IT. As highlighted in abovementioned Format file by ECHA, the information you submit will as such be available to the authorisation holder.
    </div>
);
};


export default Legal;