import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect } from 'react';
import { useAppStore } from '../store';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import { useMsal } from "@azure/msal-react";

const Authenticator = () => {
    const [state, dispatch] = useAppStore();

    const isAuthenticatedMsal = useIsAuthenticated()
    const { instance, accounts, inProgress } = useMsal();

    const name = accounts[0] && accounts[0].name;

    useEffect(() => {
        if (isAuthenticatedMsal) {
            dispatch({ type: 'LOG_IN' })
            dispatch({ type: 'SET_CURRENT_USER', currentUser: name })
        } else {
            dispatch({ type: 'LOG_OUT' })
        }
    }, [isAuthenticatedMsal])

    return state.isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />;
};

export default Authenticator;