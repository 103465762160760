import { api } from '..';


let ENDPOINT = 'api/waiving-reasons/options';
let METHOD = 'GetWaivingOptions()';

async function GetWaivingOptions() {

  try {

    const res = await api?.axios?.get(`${'api/waiving-reasons/options'}`);
    const { data } = res;

    return data;
  } catch (error) {
    log.error(`${METHOD} -`, error);
  }
  return undefined;
}


ENDPOINT = 'api/waiving-reasons/';
METHOD = 'GetWaivingReasonsSite()';

async function GetWaivingReasonsSite(site_id) {

  try {

    const res = await api?.axios?.get(`${"api/waiving-reasons/"}`, {params: {site_id: site_id}});
    const { data } = res;

    return data;
  } catch (error) {
    log.error(`${METHOD} -`, error);
  }
  return undefined;
}


ENDPOINT = 'api/waiving-reasons/';
METHOD = 'PostWaivingReasonsSite()';

async function PostWaivingReasonsSite(site_id, waiving_reasons) {

  try {

    const res = await api?.axios?.post(`${ 'api/waiving-reasons/'}`, waiving_reasons ,{params: {site_id: site_id}});
    const { data } = res;

    return data;
  } catch (error) {
    log.error(`${METHOD} -`, error);
  }
  return undefined;
}


ENDPOINT = 'api/completeness-check/check-site-completeness';
METHOD = 'CompletenessCheck()';

async function CompletenessCheck(site_id) {

  try {

    const res = await api?.axios?.get(`${ 'api/completeness-check/check-site-completeness'}`, {params: {site_id: site_id}});
    const { data } = res;

    return data;
  } catch (error) {
    log.error(`${METHOD} -`, error);
  }
  return undefined;
}

export { GetWaivingOptions, GetWaivingReasonsSite, PostWaivingReasonsSite, CompletenessCheck }
