import { api } from '../..';

const ENDPOINT = '/api/measurement/air-emission';
const METHOD = 'air()';

// Measurement Form

// async function get_measurement(measurement_id) {
//     try {
//         const res = await api?.axios?.get(`${ENDPOINT}/${measurement_id}`);
//         const { data } = res;
//         log.warn(`${METHOD} -`, data);
//         return data;
//     } catch (error) {
//         log.error(`${METHOD} -`, error);
//     }
//     return undefined;
// }

async function post_measurement(body_params) {
    try {
        const res = await api?.axios?.post(`${ENDPOINT}/add-measurement`, body_params);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function delete_measurement(measurement_id) {
    try {
        const res = await api?.axios?.delete(`${ENDPOINT}/measurement/${measurement_id}`);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function add_custom_measurement_method(query_params) {
    try {
        const res = await api?.axios?.post(`/api/air-measurement-method`, {}, { params: query_params });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

// async function patch_measurement(body_params) {
//     try {
//         const res = await api?.axios?.patch(`${ENDPOINT}/${measurement_id}`, body_params);
//         const { data } = res;
//         log.warn(`${METHOD} -`, data);
//         return data;
//     } catch (error) {
//         log.error(`${METHOD} -`, error);
//     }
//     return undefined;
// }

// Chimneys

async function get_chimneys(query_params) {
    try {
        const res = await api?.axios?.get(`${ENDPOINT}/chimneys`, { params: query_params });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function post_chimneys(body_params) {
    try {
        const res = await api?.axios?.post(`${ENDPOINT}/chimneys`, body_params);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

// Chimney Measuurement

async function post_chimney_measurement(body_params) {
    try {
        const res = await api?.axios?.post(`${ENDPOINT}/add-chimney-measurement`, body_params);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function delete_chimney_measurement(chimney_measurement_id) {
    try {
        const res = await api?.axios?.delete(`${ENDPOINT}/chimney-measurement/${chimney_measurement_id}`);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

// Overview

async function get_list(query_params) {
    try {
        const res = await api?.axios?.get(`${ENDPOINT}/list`, { params: query_params });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}


export { post_measurement, delete_measurement, add_custom_measurement_method, get_chimneys, post_chimneys, post_chimney_measurement, delete_chimney_measurement, get_list }