import clsx from "clsx";
import {
  makeStyles,
  Grid,
  Divider,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    //boxShadow: 'none',
    minWidth: "20rem",
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: "#074966",
  },
  logo: {
    height: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flexGrow: 1,
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  footerStyle: {
    borderTop: "1px solid #ddd",
    height: "90px",
    backgroundColor: "white",
  },
}));

/**
 * Renders TopBar composition
 * @class TopBar
 */
const Footer = ({
  className,
  title = "",
  onMenu,
  onNotifications,
  ...restOfProps
}) => {
  const classes = useStyles();
  return (
    <footer>
      {/* className={clsx(classes.footerStyle, className)}> */}
      <Grid container>
        <Grid container direction="row"
          justifyContent="center"
          alignItems="center" className="mb-2">
          Impressum
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="second_footer_row"
        >
          <Grid item sm={1}></Grid>
          <Grid item sm={2}>
            Ramboll Holding GmbH,
          </Grid>

          <Grid item sm={2}>
            Tel.: +49 40 302020-0
          </Grid>
          <Grid item sm={2}>
            Handelsregister Hamburg:
          </Grid>
          <Grid item sm={2}>
            Sitz der Gesellschaft:
          </Grid>
          <Grid item sm={2}>
            <a href="/legal"> Legal </a>
          </Grid>
          <Grid item sm={1}></Grid>

          <Grid item sm={1}></Grid>
          <Grid item sm={2}>
            Jürgen-Töpfer-Straße 48,
          </Grid>
          <Grid item sm={2}>
            Fax: +49 40 302020-199,
          </Grid>
          <Grid item sm={2}>
            HRB 125325,
          </Grid>
          <Grid item sm={2}>
            Hamburg, Amtsgericht Hamburg,
          </Grid>
          <Grid item sm={2}>
            In case you need asistance
          </Grid>
          <Grid item sm={1}></Grid>
          <Divider />
          <Grid item sm={1}></Grid>
          <Grid item sm={2}>
            Ramboll Holding GmbH,
          </Grid>
          <Grid item sm={2}>
            info@ramboll.de, www.ramboll.de
          </Grid>
          <Grid item sm={2}>
            USt.-Ident.-Nr.: DE 295874949
          </Grid>
          <Grid item sm={2}>
            Geschäftsführer: Bo Rene Pedersen
          </Grid>
          <Grid item sm={2}>
            please send us an{" "}
            <a href="mailto: ctacsub2@ramboll.com?subject=Regarding the CTACsub2 monitoring tool">
              {" "}
              email.
            </a>
          </Grid>
          <Grid item sm={1}></Grid>
        </Grid>
      </Grid>
      {/* <div  mt-5/>
    <div className="row">
        <div className="col-3" >
        </div>
        <div className="col-2" >
        <adress>
        Impressum
        <br/>
        Ramboll Holding GmbH
        <br/>
        Jürgen-Töpfer-Straße 48
        <br/>
        22763 Hamburg 
        </adress>
        <div className="row mb-2"/>
        <adress>
        Tel.: +49 40 302020-0
        <br/>
        Fax: +49 40 302020-199
        </adress>
        <div className="row mb-2"/>
        <adress>
        info@ramboll.de 
        <br/>
        www.ramboll.de
        </adress>
        <div className="row mb-2"/>
        <adress>
        Handelsregister Hamburg: HRB 125325
        <br/>
        USt.-Ident.-Nr.: DE 295874949
        </adress>
        <div className="row mb-2"/>
        <adress>
        Sitz der Gesellschaft: Hamburg
        <br/>
        Amtsgericht Hamburg
        <br/>
        Geschäftsführer: Bo Rene Pedersen 
        </adress>
        <div className="col-3" />
        </div>
        <div className="col-3" >
          <div className="row">
            
          </div>  
          <div className="row">
            <adress>
              <div className="row col-md-1 ">
               <a href="/legal"> Legal </a>
              </div>
              <br/>
              In case you need asistance please send us an <a href = "mailto: ctacsub2@ramboll.com?subject=Regarding the CTACsub2 monitoring tool"> email.</a>
              </adress>
          </div>  
        </div>
        
     </div>  */}
    </footer>
  );
};

export default Footer;
