import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import logLevel from '../src/utils/log';

import { msalInstance } from './components/msal';
import { MsalProvider } from "@azure/msal-react";

if (process.env.REACT_APP_MOCK_APIS === 'true') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

global.log = logLevel; // or: var log = logLevel;
log.info('App started :)');

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
