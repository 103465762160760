import { api } from '..';

const ENDPOINT = 'api/data-export';
const METHOD = 'Data Export()';

export async function Report(company_id, year_id, substance_id, include_air_emissions, include_biomonitoring, include_exposures, include_water_emissions) {
  try {
    const res = await api?.axios?.get(ENDPOINT, {
      params: { company_id: company_id,
        year_id: year_id, substance_id: substance_id,
        include_air_emissions: include_air_emissions, include_biomonitoring: include_biomonitoring,
        include_exposures: include_exposures, include_water_emissions: include_water_emissions
      }, responseType: 'blob'
    });
    return res;
  } catch (error) {
    log.error(`${METHOD} -`, error);
  }
  return undefined;
}

export default Report;