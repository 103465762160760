import { useState, useEffect } from "react"

export default function useApi(api_call, api_props) {
    const [loading, setLoading] = useState(true)

    const [result, setResult] = useState()

    useEffect(() => {
        const async_api_call = async () => {
            const api_response = await api_call(api_props)
            setResult(api_response)
            setLoading(false)
        }
        async_api_call()
    }, [])

    return [loading, result]
}