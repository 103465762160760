import React from 'react';
/**
 * Note: Don't import/export all Views directly, use lazy loading!
 */
import { withSuspense } from '../components';
import NotFound from './NotFound';
import NotImplementedView from './NotImplemented';
import Legal from './Legal'
/**
 * Views/Pages with Lazy Loading
 */
const Sites = withSuspense(React.lazy(() => import('./Sites')));
const MeasurementForms = withSuspense(React.lazy(() => import('./MeasurementForms')))

export { NotFound, Sites, MeasurementForms, Legal };
