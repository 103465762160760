import axios from 'axios';
import { api } from '.';
import { localStorageGet } from '../utils/localStorage';
import { loginRequest } from '../authConfig';
import { useMsal } from '@azure/msal-react'
import { noConflict } from 'loglevel';
import { msalInstance } from '../components/msal'

async function RequestAccessToken() {
  const account = msalInstance.getAllAccounts()[0];

  const request = {
    ...loginRequest,
    account: account
  };

  const token = await (await msalInstance.acquireTokenSilent(request)).accessToken
  return token

}


/**
 * Pre-configured instance of Axios
 */
const axiosInstance = axios.create({
  // Use Local storage to override API base path

  //baseURL: `${localStorageGet('REACT_APP_API_URL') || process.env.REACT_APP_API_URL}/`,  
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.defaults.headers.common['token'] = '1';
/**
 * Interceptor to automatically logout current user if any API call returns 401
 */
axiosInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (Number(err?.response?.status) === 401) {
      // Token expired, or hacked
      api?.auth?.logout(); // Logout user and reload Application
    }
    return Promise.reject(err);
  }
);

if (process.env.REACT_APP_DEV_LOGIN_TOKEN === 'true') {
  axiosInstance.defaults.headers.common['token'] = '1';
} else {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await RequestAccessToken()
      config.headers['token'] = token
      return config
    }
  )
}


export default axiosInstance;
