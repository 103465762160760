import AppIcon from "../AppIcon"
import { AppIconButton } from "../AppIconButton"
import { Collapse } from "@material-ui/core"
import { useState } from "react"


export default function AccordionInfobox(props) {

    const { content, accordion_id } = props

    return (
        <div className="infobox">
            <AppIcon
                icon='help_outline'> </AppIcon>
            <div className="infobox_text p-0 w-100">
                <div class="accordion" id="infobox_accordion">
                    {Object.entries(content).map(([key, element]) => (
                        <div class="accordion-item">
                            <h2 class="accordion-header" id={`infobox_accordion_item_heading_${key}`}>
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#infobox_accordion_item_${key}`} aria-expanded="true" aria-controls={`infobox_accordion_item_${key}`}>
                                    <div>{element.header}</div>
                                </button>
                            </h2>
                            <div id={`infobox_accordion_item_${key}`} class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#infobox_accordion">
                                <div class="accordion-body">
                                    <p>
                                        {element.text}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}