import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, TextField, Card, CardHeader, CardContent, InputAdornment } from '@material-ui/core';
import { useAppStore } from '../../../store';
import { AppButton, AppIconButton, AppLink } from '../../../components';
import { AppForm, AppAlert } from '../../../components/forms';
import { useAppForm, SHARED_CONTROL_PROPS, eventPreventDefault } from '../../../utils/form';
import { api } from '../../../api';

import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../../../components/SignInButton";

const LoginEmailView = () => {

  const isAuthenticated = useIsAuthenticated();

  return (
    <div className="home">
      <div className="welcome__container">
        <div class="h3">
          Welcome to CTACSub2
        </div>
        <p>
        Dear user,
        thanks for using the CTACSub2 tool (please note: Being a member of CTACSub2 is necessary to access the tool).
        The CTACSub2 tool provides an easy-to-use digital questionnaire for entering occupational and environmental monitoring data related to your use of chromium trioxide (CrO3).
        Click "login" to start the data entry!
        </p>
        <div className="mt-2">
          <SignInButton />
        </div>
      </div>
    </div>
  );
};

export default LoginEmailView;
