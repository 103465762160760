import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Dialog, DialogActions, DialogContent, TextField,
  Grid,
  Typography, FormControlLabel, Checkbox
} from '@material-ui/core';
import { AppButton } from '../../components';
import { AppDialogTitle } from '../../components/dialogs/components';
import { dialogStyles } from '../../utils/styles';
import { api } from "../../api";
import { useAppStore } from "../../store";
import { getCompanyLocalStorage, getYearLocalStorage } from '../../utils/localStorage';
import { saveAs } from 'file-saver';
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  ...dialogStyles(theme),
  edit: {
    margin: 0,
  },
}));


const ExportReportDialog = ({ include_exposures, include_air_emissions, include_water_emissions, include_biomonitoring, open = false, title, onConfirm, onClose, ...props }) => {
  const classes = useStyles();
  const [store_state, dispatch] = useAppStore();
  const [state, setState] = useState({ include_exposures: false, include_air_emissions: false, include_water_emissions: false, include_biomonitoring: false });
  const handleInputChange = useCallback((event) => {

    const name = event.target?.name;
    const value = event.target?.checked;
    setState((state) => ({
      ...state,
      [name]: value
    }));
  }, []);

  const handleOnConfirm = async () => {
    var substance_id = 1;
    const response = await api?.sites?.Report(getCompanyLocalStorage().company_id, getYearLocalStorage().year_id, substance_id, state.include_air_emissions, state.include_biomonitoring, state.include_exposures, state.include_water_emissions);
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    // const filename = response.headers['content-disposition']
    //     .split(';')
    //     .find((n: any) => n.includes('filename='))
    //     .replace('filename=', '')
    //     .trim();
    let url = window.URL
      .createObjectURL(new Blob([response.data]));
    saveAs(url, "Report.xlsx");
  };

  return (
    <Dialog
      className={classes.root}
      classes={{ paper: classes.paper }}
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      {...props}
    >
      <AppDialogTitle id="form-dialog-title" onClose={onClose}>
        {title || 'Export Report'}
      </AppDialogTitle>
      <DialogContent>
        <Grid container spacing={1} >
          <Grid item sm={10}>
            <Typography variant="h6">Occupational / Workplace exposure</Typography>
          </Grid>
          <Grid item sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.include_exposures}
                  onChange={handleInputChange}
                  name="include_exposures"
                />
              }
            />
          </Grid>
          <Grid item sm={10}>
            <Typography variant="h6">Air emissions</Typography>
          </Grid>
          <Grid item sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.include_air_emissions}
                  onChange={handleInputChange}
                  name="include_air_emissions"
                />
              }
            />
          </Grid>
          <Grid item sm={10}>
            <Typography variant="h6">Wastewater emissions</Typography>
          </Grid>
          <Grid item sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.include_water_emissions}
                  onChange={handleInputChange}
                  name="include_water_emissions"
                />
              }
            />
          </Grid>
          <Grid item sm={10}>
            <Typography variant="h6">Biomonitoring</Typography>
          </Grid>
          <Grid item sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.include_biomonitoring}
                  onChange={handleInputChange}
                  name="include_biomonitoring"
                />
              }
            />
          </Grid>
        </Grid>
        <div className="alert alert-primary" role="alert">
          Note that this exports an excel file with xlsx format. If the export does not work, please check your IT policy for possible restrictions regarding downloading files with this format.
            Please make sure that a substance is registered for all sites. If you have empty sites that you want to delete, please send us an <a href="mailto:ctacsub2@ramboll.com?subject=CTACSub2%20Monitoring%20Tool:%20Please%20Delete%20Site">email</a>.
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <AppButton onClick={onClose}>Cancel</AppButton>
        <AppButton onClick={handleOnConfirm} color="primary" mr={0}>
          Export Report
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};

ExportReportDialog.propTypes = {
  include_exposures: PropTypes.bool,
  include_air_emissions: PropTypes.bool,
  include_water_emissions: PropTypes.bool,
  include_biomonitoring: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExportReportDialog;
