import { api } from '..';


const Add_ENDPOINT = 'api/substance';
const Add_METHOD = 'AddSubstance()';


async function Add(payload) {

  try {

    log.info('substance put:', payload)
    const res = await api?.axios?.put(Add_ENDPOINT, payload);
    const { data } = res;
    log.warn(`${Add_METHOD} -`, data);
    return data;
  } catch (error) {
    log.error(`${Add_METHOD} -`, error)
    log.error(error.response);
  }
  return undefined;
}

const Get_ENDPOINT = 'api/substance';
const Get_METHOD = 'GetSubstance()';

async function Get(site_id, substance_id, year_id) {

  try {

    const res = await api?.axios?.get(`${Get_ENDPOINT}`, { params: { site_id: site_id, substance_id: substance_id, year_id: year_id } });
    const { data } = res;
    log.warn(`${Get_METHOD} -`, data);

    return data;
  } catch (error) {
    log.error(`${Get_METHOD} -`, error);
  }
  return undefined;
}

const GetAll_ENDPOINT = 'api/site/substance-overview';
const GetAll_METHOD = 'GetAllSubstanceForSite()';
async function GetAll(site_id, year_id) {
  try {

    const GetAll_ENDPOINT = 'api/site/substance-overview';
    const res = await api?.axios?.get(GetAll_ENDPOINT, { params: { site_id: site_id, year_id: year_id } });
    const { data } = res;
    log.warn(`${GetAll_METHOD} -`, data);
    return data;
  } catch (error) {
    log.error(`${GetAll_METHOD} -`, error);
  }
  return undefined;
}

async function getStatus(site_id, substance_id, year_id) {
  try {

    const GetAll_ENDPOINT = 'api/substance/status-overview';
    const res = await api?.axios?.get(GetAll_ENDPOINT, { params: { site_id: site_id, year_id: year_id, substance_id: substance_id } });
    const { data } = res;
    log.warn(`${getStatus} -`, data);

    return data;
  } catch (error) {
    log.error(`${GetAll_METHOD} -`, error);
  }
  return undefined;
}


async function pachtStatus(payload) {
  try {
    const GetAll_ENDPOINT = 'api/substance/status-overview';
    const res = await api?.axios?.patch(GetAll_ENDPOINT, payload);
    const { data } = res;
    log.warn(`${GetAll_METHOD} -`, data);

    return data;
  } catch (error) {
    log.error(`${GetAll_METHOD} -`, error);
  }
  return undefined;
}

async function removeLicenses(query_params, body_params) {
  try {
    const res = await api?.axios?.patch('api/substance/remove-license-from-substance-entry', body_params, { params: query_params });
    const { data } = res;
    log.warn(`${GetAll_METHOD} -`, data);

    return data;
  } catch (error) {
    log.error(`${GetAll_METHOD} -`, error);
    return error
  }
  return undefined;
}


export { Add, Get, GetAll, getStatus, pachtStatus, removeLicenses };
