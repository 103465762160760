import api from "..";

const ENDPOINT = '/api/company/';
const METHOD = 'companies()';


async function get() {
    try {
        const res = await api?.axios?.get(ENDPOINT);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
        log.error(error.response)
    }
    return undefined;
}


async function is_registered() {
    try {
        const res = await api?.axios?.get('/api/auth');
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
        log.error(error.response)
    }
    return undefined;
}


export { get, is_registered }