import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {useState} from 'react';

import AppTextField from '../../components/AppForm/AppTextField';

export default function RenameSiteDialog({close, rename_site, site}) {

    const [newName, setNewName] = useState(site.name)

    return (
        <div>
            <Dialog
                open={true}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Rename Site</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <form id="rename_site">
                            <AppTextField type="text" name="new_site_name" onChange={(e) => {
                                    setNewName(e.currentTarget.value)
                            }} form_id="rename_site" value={newName}/>
                        </form>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => rename_site(site.id, newName)} color="primary">
                        Save
                    </Button>
                    <Button onClick={close} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
