import {LinearProgress} from "@material-ui/core";
import {useEffect, useState} from 'react';
import Selectrix from 'react-selectrix';

import {api} from '../../api';
import RenameSiteDialog  from "./RenameSiteDialog";

export default function SiteControlPanel({selected_company, companies}){

    const [loading, setLoading] = useState(true)
    const [sites, setSites] = useState(true)
    const [newCompany, setNewCompany] = useState(undefined)
    const [siteToRename, setSiteToRename] = useState(undefined)

    useEffect(()=> {
         const fetchSites = async() => {
            const sites = await api.admin_panel.admin_panel.get_sites_of_company(selected_company.key)
            if (sites !== undefined){
                setSites(sites)
                setLoading(false)
            }
         }
         fetchSites()
    }, [selected_company])

    const move_to_new_company = async (site_id) => {
        let filtered_sites = sites.filter(site => site.id!==site_id)
        setSites(filtered_sites)
        let res = await api.admin_panel.admin_panel.move_site(site_id, newCompany.key)
    }

    const rename_site = async (site_id, new_site_name) => {
        let renamed_sites = sites.map(site => {
            if (site.id!==site_id){
                return site
            }
            return {...site, name:new_site_name}
        })
        setSites(renamed_sites)
        let res = await api.admin_panel.admin_panel.rename_site(site_id, new_site_name)
        setSiteToRename(undefined)
    }


    if (loading){
        return <LinearProgress />
    }

    return (
        <div>
            {siteToRename &&
            <RenameSiteDialog rename_site={rename_site} close={() => setSiteToRename(undefined)} site={siteToRename}/>
            }
            <hr/>
                {sites.map(site => (
                    <div className="row">
                        <div className="col">{site.name}</div>
                        <div className="col">
                            <button className="btn btn-primary" onClick={() => move_to_new_company(site.id)}>Move</button>
                        </div>
                        <div className="col">
                            <button className="btn btn-primary" onClick={() => setSiteToRename(site)}>Rename</button>
                        </div>
                    </div>
                ))}
            <hr/>
            <div className="row">
            <div className="col">
                <div>Choose a Company to which you want to move the site</div>
                <Selectrix
                    materialize={false}
                    onChange={(value) => setNewCompany(value)}
                    defaultValue={newCompany?.key}
                    options={companies}
                    customKeys={{key: "id", label:'name'}}
                />
                </div>
            </div>
        </div>
    )
}
