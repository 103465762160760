import { useCallback, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppLink from "../../components/AppLink";
import AppIconButton from "../../components/AppIconButton";
import AppButton from "../../components/AppButton";
import { api } from "../../api";
import { useAppStore } from "../../store";
import { getCompanyLocalStorage, getSiteLocalStorage, getYearLocalStorage, setSiteLocalStorage, setYearLocalStorage } from "../../utils/localStorage";
import { useHistory } from "react-router";
import { AppIcon } from "../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
  },
}));

const SiteControl = (props) => {

  const history = useHistory()

  const classes = useStyles();
  const [sites, setSites] = useState({});
  const [years, setYears] = useState({});
  const [state, dispatch] = useAppStore();
  const [isLoadingSites, setisLoadingSites] = useState(true)
  const [isLoadingSelectOptions, setisSelectOptions] = useState(true)


  useEffect(() => {
    const getAllSites = async () => {
      const apiResult = await api?.sites?.site?.GetAllSites(getCompanyLocalStorage().company_id);
      setSites(apiResult);
      setisLoadingSites(false)
    };
    const getAllSelectOptions = async () => {
      const select_options = await api?.select_options?.select_options?.get();
      if (select_options) {

        setYears(select_options.year);
        setisSelectOptions(false)
      }

    };
    getAllSites();
    getAllSelectOptions();
  }, []);

  const handleSelection = useCallback((site_id, site_name) => {
    setSiteLocalStorage(site_id, site_name)
    dispatch({
      type: 'SET_Site',
      selectedSiteId: site_id,
      selectedSiteName: site_name,
    });
  }, [dispatch]);

  const handleYearSelection = useCallback((year, year_id) => {
    setYearLocalStorage(year_id, year)
    dispatch({
      type: 'SET_Year',
      selectedYear: year,
      selectedYearID: year_id
    });
  }, [dispatch]);

  const delete_site = async (site_id, index) => {
    delete sites[index]
    setSites(sites)
    if (state.selectedSiteId === site_id && Object.keys(sites).length !== 0) {
      dispatch({ type: 'SET_Site', selectedSiteId: sites[0].id, selectedSiteName: sites[0].name })
    }
    await api.sites.site.SiteDeleteAxios(site_id)
    if (Object.keys(sites).length === 0) {
      history.push('/site/add_site')
    }
  }

  const renderSites = () => {
    if (isLoadingSites) {
      return <CircularProgress className="mx-auto mt-3 mb-2" />
    }

    return (<ul>
      {Object.keys(sites).map((index) => (
        <li className={String(getSiteLocalStorage().site_id) === String(sites[index].id) ? 'active' : ''}>
          <AppLink href={'#'} openInNewTab={false} onClick={() => handleSelection(sites[index].id, sites[index].name)}>{sites[index].name}</AppLink>
          <div className="edit-icons">

            <AppIconButton
              icon="edit"
              size="small"
              title="Edit site details"
              onClick={() => history.push(`/site/edit_site/${sites[index].id}`)}
            />
            {/* <AppIconButton
              icon="delete"
              size="small"
              title="Delete Site"
              onClick={() => delete_site(sites[index].id, index)}
            /> */}
          </div>
        </li>
      ))}
    </ul>
    )

  }

  const renderYears = () => {
    if (isLoadingSelectOptions) {
      return <CircularProgress className="mx-auto mt-3 mb-2" />
    }

    let sorted_years = Object.entries(years).sort(([,a],[,b]) => parseInt(b) - parseInt(a))

    return (
      <ul>
        {sorted_years.map(([key, year]) => (
          <li className={String(getYearLocalStorage().year_id) === String(key) ? 'active' : ''}>
            <AppLink href={'#'} openInNewTab={false} onClick={() => handleYearSelection(year, key)}>{year}</AppLink>
          </li>
        ))}
      </ul>
    )

  }


  return (
    <Card className={`${classes.root} site-control pt-0`}>
      <div>
        <a onClick={() => history.push('/')}><AppIcon icon="arrowback" /> Change Company</a>
      </div>
      <CardContent>
        <Grid container direction="column" className="sites">
          <Typography variant="h5">Sites
            <AppIconButton
              icon="add"
              title="Add Site"
              onClick={() => history.push("/site/add_site")}
            ></AppIconButton>
          </Typography>
          {renderSites()}
          {/* <AppIconButton
            icon="add"
            title="Add Site"
            onClick={() => history.push("/site/add_site")}
          ></AppIconButton> */}
        </Grid>
        <Grid container direction="column">
          <Typography variant="h5">Years</Typography>
          {renderYears()}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SiteControl;
