import { api } from '..';


const ENDPOINT = 'api/site';
const METHOD = 'AddSite()';

async function SiteAddAxios(company_id, payload) {
  try {

    const res = await api?.axios?.post(`${ENDPOINT}/${company_id}`, payload);
    const { data } = res;
    log.warn(`${METHOD} -`, data);

    return data;
  } catch (error) {
    log.error(`${METHOD} -`, error);
  }
  return undefined;
}

const Update_ENDPOINT = 'api/site';
const Update_METHOD = 'UpdateSite()';

async function SiteUpdateAxios(payload) {
  // const payload = { name, contact_email, contact_name, country_id, company_id };
  try {

    const res = await api?.axios?.put(Update_ENDPOINT, payload);
    const { data } = res;
    log.warn(`${Update_METHOD} -`, data);

    return data;
  } catch (error) {
    log.error(`${METHOD} -`, error);
  }
  return undefined;
}

const Delete_ENDPOINT = 'api/site';
const Delete_METHOD = 'DeleteSite()';

async function SiteDeleteAxios(site_id) {
  try {

    const res = await api?.axios?.delete(Delete_ENDPOINT, { data: { site_id: site_id } });
    const { data } = res;
    log.warn(`${Delete_METHOD} -`, data);

    return data;
  } catch (error) {
    log.error(`${Delete_METHOD} -`, error);
  }
  return undefined;
}


const Get_ENDPOINT = 'api/site/sites';
const Get_METHOD = 'GetSites()';

async function GetAllSites(company_id) {

  try {

    const res = await api?.axios?.get(`${Get_ENDPOINT}/${company_id}`);
    const { data } = res;
    log.warn(`${Get_METHOD} -`, data);

    return data;
  } catch (error) {
    log.error(`${Get_METHOD} -`, error);
  }
  return undefined;
}

export { SiteAddAxios, SiteUpdateAxios, GetAllSites, SiteDeleteAxios };