import { PropTypes  } from 'prop-types';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import { paperStyle } from '../../utils/styles';

const APP_SECTION_VARIANT = 'subtitle2'; // 'subtitle1' | 'body1' | 'h6'
const useStyles = makeStyles((theme) => ({
    paper: paperStyle(theme),
  }));

  const AppSection = ({ children, title = 'Missing title...', variant = APP_SECTION_VARIANT, ...restOfProps }) => {
    const classes = useStyles();
    return (
      <Paper className={classes.paper} {...restOfProps}>
        <Typography variant={variant}>{title}</Typography>
        {children || 'Under constriction...'}
      </Paper>
    );
  };

  AppSection.propTypes = {
    align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
    children: PropTypes.node,
    color: PropTypes.oneOf(['initial', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary', 'error']),
    title: PropTypes.node.isRequired,
    variant: PropTypes.oneOf([
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'subtitle1',
      'subtitle2',
      'body1',
      'body2',
      'caption',
      'button',
      'overline',
      'srOnly',
      'inherit',
    ]),
  };
  
  export default AppSection;