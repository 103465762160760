import { useState, useEffect } from "react";
import {CircularProgress} from "@material-ui/core";
import { saveAs } from 'file-saver';

import { AppButton, AppIconButton } from '../../components';
import AppLink from "../../components/AppLink";
import { api } from "../../api";
import { useHistory } from "react-router-dom";

import YearRangeDialog from "../../components/Admin/Export/YearRangeDialog"

export default function ExportView(){


    const [years, setYears] = useState({});
    const [currentYear, setCurrentYear] = useState("");
    const [isLoadingYears, setisLoadingYears] = useState(true)
    const [isDownloading, setIsDownloading] = useState("")
    const [yearRangeDialog, setYearRangeDialog] = useState({})
    const history = useHistory();


    useEffect(() => {
        const getAllSelectOptions = async () => {
          const select_options = await api?.select_options?.select_options?.get();
          if (select_options) {

            setYears({...select_options.year, 0: "All Years"});
            setCurrentYear(Object.keys(select_options.year)[0])
            setisLoadingYears(false)
          }

        };
        getAllSelectOptions();
    }, []);

    const renderYears = () => {
        if (isLoadingYears) {
          return <CircularProgress className="mx-auto mt-3 mb-2" />
        }

        let sorted_years = Object.entries(years).sort(([,a],[,b]) => parseInt(b) - parseInt(a))

        return (
          <ul>
            {sorted_years.map(([key, year]) => (
              <li className={currentYear === String(key) ? 'active' : ''}>
                <AppLink href={'#'} openInNewTab={false} onClick={() => setCurrentYear(key)}>{year}{currentYear === String(key) ? ' X' : ''}</AppLink>
              </li>
            ))}
          </ul>
        )

    }

    const generateExportAllCompanies = async () => {
        const response = await api?.admin_panel?.admin_panel.generate_export_all_companies(currentYear, false, currentYear==='0');
    }

    const generateExportAllCompaniesZip = async () => {
        const response = await api?.admin_panel?.admin_panel.generate_export_all_companies(currentYear, true, currentYear==='0');
    }

    const generateExportCompanyStatus = async () => {
        //const response = await api?.admin_panel?.admin_panel.generate_export_company_status(currentYear);
      setYearRangeDialog({
        api: async (start_year, end_year) =>
          await api?.admin_panel?.admin_panel.generate_export_company_status(start_year, end_year)
      })
    }

    const generateExportAggregatedEmissions = async () => {
        //const response = await api?.admin_panel?.admin_panel.generate_aggregated_emissions_export(currentYear);
        setYearRangeDialog({
          api: async (start_year, end_year) =>
            await api?.admin_panel?.admin_panel.generate_aggregated_emissions_export(start_year, end_year)
        })
    }

    const renderYearRangeDialog = () => {
      if (Object.keys(yearRangeDialog).length === 0){
        return <></>
      }
      return <YearRangeDialog
                years={years}
                api={yearRangeDialog.api}
                open={true}
                onClose={() => setYearRangeDialog({})}
                defaultYear={currentYear}
        />
    }

    return (
      <div>
        <AppIconButton icon="arrowback" onClick={() => history.push("/admin")} />
        <div className="row">
            <div className="col-3">
                {renderYears()}
            </div>
            {renderYearRangeDialog()}
            <div className="col">
              <div className="mt-3">
                <a href="#" className={`btn btn-primary`} href="#" onClick={generateExportAllCompanies}>
                  Generate export all companies data in one big excel file
                </a>
              </div>
              <div className="mt-3">
                <a href="#" className={`btn btn-primary`} href="#" onClick={generateExportAllCompaniesZip}>
                  Generate export all companies data as zipfile with one excel file per company
                </a>
              </div>
              <div className="mt-3">
                <a href="#" className={`btn btn-primary`} href="#" onClick={generateExportCompanyStatus}>
                  Generate export about progress status of the companies.
                </a>
              </div>
              <div>
                <CachedExportFiles/>
              </div>
            </div>
        </div>
      </div>
    )
}


function CachedExportFiles(){

  const [loading, setLoading] = useState(true)
  const [listOfCachedFiles, setListOfCachedFiles] = useState(true)
  const loadListOfCachedFiles = async() => {
    setLoading(true)
    const response = await api?.admin_panel?.admin_panel.get_cached_export_files();
    setListOfCachedFiles(response)
    setLoading(false)
  }

  useEffect(() => {

    loadListOfCachedFiles()
  }, [])

  const download = async(fileName) => {
        const response = await api?.admin_panel?.admin_panel.download_chached_export_file(fileName);
        let url = window.URL
          .createObjectURL(new Blob([response.data]));
        saveAs(url,fileName);
  }

  const deleteFiles = async() => {
    const response = await api?.admin_panel?.admin_panel.delete_cached_export_files();
    loadListOfCachedFiles()
  }

  const renderFileList = () => {
    if(loading){
      return <CircularProgress/>
    }

    return (
        <ul>
          {listOfCachedFiles.map((fileName) =>
            (
              <li key={fileName}>
                <a href="#" onClick={() => {download(fileName)}}>{fileName}</a>
              </li>)
            )
          }
        </ul>
    )
  }

  return (
      <div className="container mt-3">
        <div>
          <span className="h6">Available Exports:</span>
          <a className="ms-3 btn-primary btn" href="#" onClick={loadListOfCachedFiles}>Refresh</a>
        </div>
        {renderFileList()}
        <a className="ms-3 btn-primary btn" href="#" onClick={deleteFiles}>Delete Files</a>
      </div>
  )
}
