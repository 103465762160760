import {useEffect, useState} from 'react';
import Selectrix from 'react-selectrix';
import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  LinearProgress,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";

import CreateUser from './CreateUser'
import {api} from '../../api';
import ConfirmationDialog from '../../utils/ConfirmationDialog'

export default function CompanyControlPanel({selected_company, ...restOfProps}){

    const [loading, setLoading] = useState(true)
    const [all_users, set_all_users] = useState([])
    const [users_of_company, set_users_of_company] = useState([])
    const [selected_user, set_selected_user] = useState({})
    const [user_to_delete, set_user_to_delete] = useState(undefined)

    useEffect(()=> {

         const fetch_users_of_company = async(selected_company) => {
            const users_of_company = await api.admin_panel.admin_panel.get_all_users_of_company(selected_company.key)
            if (users_of_company !== undefined){
                set_users_of_company(users_of_company)
                setLoading(false)
            }
         } 
         setLoading(true)
         fetch_users_of_company(selected_company)
    }, [selected_company])

    useEffect(()=> {

         const fetch_all_users = async() => {
            const all_users = await api.admin_panel.admin_panel.get_all_users()
            if (all_users !== undefined){
                set_all_users(all_users)
            }
         } 
         fetch_all_users()
    }, [])

    if (loading){
        return <CircularProgress />
    }

    const add_user_to_company = async(user) => {
       const res = await api.admin_panel.admin_panel.add_user_to_company(user.key, selected_company.key) 
        if (res !== undefined){
            const updated_users = users_of_company.concat({id: user.key, email: user.label})
            set_users_of_company(updated_users)
        }
    }

    const remove_user_form_company = async(user_id) => {
       const res = await api.admin_panel.admin_panel.remove_user_from_comapny(user_id, selected_company.key) 
        if (res !== undefined){
            set_users_of_company(users_of_company.filter(user => user.id !== user_id))
            set_user_to_delete(undefined)
        }
    }

    const add_new_user_to_all_users_list = (user_id, user_email) => {
        const updated_all_users = all_users.concat({id: user_id, email: user_email})
        set_all_users(updated_all_users)
        set_selected_user({key: user_id, label: user_email})
        add_user_to_company({key: user_id, label: user_email})
    }

    const render_deletion_confirmation = () => {
        if (user_to_delete !== undefined){
            return <ConfirmationDialog 
                text={`Remove user "${user_to_delete.email}" from company "${selected_company.label}"?`}
                positive={'Remove User'}
                negative={'Cancel'}
                handleAnswer={(answer) => answer ? remove_user_form_company(user_to_delete.id) : set_user_to_delete(undefined)}
            />
        }
        return <></>
    }

    return (
        <>
        {render_deletion_confirmation()}
        <div className="row"> 
            <div className="col"> 
                Email
            </div>
            <div className="col"> 
                Remove from Comapany
            </div>
        </div>
        <hr/>
        {users_of_company.length === 0 &&
        <div className="row"> 
            <div className="col"> 
                No users have access to this company so far.
            </div>
        </div>
        }
        {users_of_company.map(user => (
        <div className="row"> 
            <div className="col"> 
            {user.email}
            </div>
            <div className="col"> 
                <button className="btn btn-primary" onClick={() => set_user_to_delete(user)}>Delete</button>
            </div>
        </div>
        ))}
        <hr/>
        <div className="row mb-3"> 
            <div className="col"> 
                <div>
                    Choose user from list of existing users:
                </div>
                <Selectrix
                    materialize={false}
                    onChange={(value) => set_selected_user(value)}
                    defaultValue={selected_user?.key}
                    options={all_users}
                    customKeys={{key: "id", label:'email'}}
                />
                <button className="btn btn-primary mt-2" onClick={() => add_user_to_company(selected_user)}>Add user to company</button>
            </div>
        </div>
        <div className="row"> 
            <div className="col"> 
                <CreateUser add_new_user_to_all_users_list={add_new_user_to_all_users_list} /> 
            </div>
        </div>
        </>
    )
}
