import { useCallback } from "react"
import Selectrix from 'react-selectrix'
import { Select } from "@material-ui/core"


export default function AppTextField({ label, form_id, name, className, error, helperText, control_type, text_after, type, onChange, ...restOfProps }) {

    if (!control_type) {
        control_type = 'input'
    }

    const render_control = (control_type) => {
        switch (control_type) {
            case 'input':

                // Normal fields
                if (type !== "Number" && type !== "number") {
                    return <input
                        className={`form-control ${error ? 'is-invalid' : ''}`}
                        type={type}
                        {...restOfProps}
                        name={name}
                        onChange={onChange}
                        id={`${form_id}__${name}`}
                    />

                }

                // if type is number firefox html validation fails
                // to fix this, all fields with numbers are rendered as textfields,
                // but onChange is wrapped into a function that prevents entering other 
                // chars than 0-9 . - and e. 
                const onChangeWrap = (event) => {

                    if (type !== "Number" && type !== "number") {
                        onChange(event)
                    }
                    let value = event.target.value
                    if (value.split('.').length > 2) {
                        event.target.value = event.target.defaultValue
                    }
                    if (value.split('e').length > 2) {
                        event.target.value = event.target.defaultValue
                    }
                    if (value.split('-').length > 2) {
                        event.target.value = event.target.defaultValue
                    }
                    value = value.replace(/^\-*[0-9]*\.*[e0-9]*/gi, '')
                    if (value !== '') {
                        event.target.value = event.target.defaultValue
                    }
                    onChange(event)
                }

                delete restOfProps.type
                return <input
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                    type={"text"}
                    {...restOfProps}
                    name={name}
                    onChange={onChangeWrap}
                    id={`${form_id}__${name}`}
                />
            case 'selectrix':
                return (
                    <div className={`form-control selectrix ${error ? 'is-invalid' : ''}`}>

                        <Selectrix
                            materialize={false}
                            onChange={onChange}
                            {...restOfProps}
                            name="country_id"
                        />
                    </div>
                )
            case 'select':
                return (
                    <Select />
                )
        }
    }

    return (
        <div className={`${className} form-group ${control_type}-group ${error ? 'is-invalid' : ''}`}>
            <div className="label">
                <label for={`${form_id}__${name}`}>{label}</label>
            </div>
            <div className="input">
                {render_control(control_type)}
                <div className="text-after">
                    {text_after}
                </div>
                {error &&
                    <div class="invalid-feedback">
                        {helperText}
                    </div>
                }
            </div>
        </div>
    )
}