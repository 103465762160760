import { api } from '../..';

const ENDPOINT = '/api/measurement/biomonitoring';
const METHOD = 'biomonitoring()';

async function get_measurement(query_params, measurement_id,) {
    try {
        const res = await api?.axios?.get(`${ENDPOINT}/${measurement_id}`, {params: query_params});
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}


async function get_all_measurements(body_params) {
    try {
        const res = await api?.axios?.get(`${ENDPOINT}`, {params:body_params});
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function post_measurement(body_params) {
    try {
        const res = await api?.axios?.post(`/api/measurement/biomonitoring/add-measurement`, body_params);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function patch_measurement(body_params, measurement_id) {
    try {
        const res = await api?.axios?.patch(`${ENDPOINT}/${measurement_id}`, body_params);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function delete_measurement(measurement_id) {
    try {
        const res = await api?.axios?.delete(`${ENDPOINT}/${measurement_id}`);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

export { get_measurement, get_all_measurements , post_measurement, patch_measurement ,delete_measurement}