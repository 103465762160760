import { api } from '../';

const ENDPOINT = '/api/select_options';
const METHOD = 'select_options()';

function merge_option_lists(data) {
    // api returns lists of dictionaries with one entry each
    // this function merges each list into a single dict

    let options = {}

    Object.entries(data).forEach(([option_type, list]) => {
        let option_type_list = {}
        list.forEach((list_elem) => {
            if (Object.keys(list_elem).includes('name')) {
                option_type_list[list_elem.id] = list_elem.name
            }
            if (Object.keys(list_elem).includes('type')) {
                option_type_list[list_elem.id] = list_elem.type
            }
            if (Object.keys(list_elem).includes('label')) {
                option_type_list[list_elem.id] = list_elem.label
            }
            if (Object.keys(list_elem).includes('value')) {
                option_type_list[list_elem.id] = list_elem.value
            }
        })
        options[option_type] = option_type_list
    })

    return options
}

async function get() {
    try {
        const res = await api?.axios?.get(ENDPOINT);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return merge_option_lists(data);
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}



async function getLicenses() {
    try {
        const res = await api?.axios?.get('/api/licenses');
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return Object.fromEntries(data.map(elem => ([elem.id, elem.name])));
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function license_specific_task(header_params) {
    try {
        const res = await api?.axios?.get('/api/licence_specific_tasks', { params: header_params });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return Object.fromEntries(data.map(elem => ([elem.id, elem.name])));
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}



export { get, getLicenses, license_specific_task }