import {useEffect, useState} from 'react';
import {api} from '../../api';
import ConfirmationDialog from '../../utils/ConfirmationDialog'

export default function CreateUser({add_new_user_to_all_users_list}){
    const [new_user_email, set_new_user_email] = useState("")
    const [wait_for_confirmatation, set_wait_for_confirmation] = useState(false)
    const handleChange = (e) => {
        set_new_user_email(e.currentTarget.value)
    }

    const post_user = async() => {
        if (new_user_email !== ""){
            const new_user_id = await api.admin_panel.admin_panel.create_user(new_user_email)
            if (new_user_id !== undefined){
                add_new_user_to_all_users_list(new_user_id, new_user_email)
                set_new_user_email("")
                set_wait_for_confirmation(false)
            }
        }
    }

    const render_confirmation = () => {
        if (wait_for_confirmatation){
            return <ConfirmationDialog 
                text={`Create user with email "${new_user_email}"?`}
                positive={'Create User'}
                negative={'Cancel'}
                handleAnswer={(answer) => answer ? post_user() : set_wait_for_confirmation(false)}
            />
        }
        return <></>
    }

   return (
       <div> 
            {render_confirmation()}
            <div>
            Create a new user and add this user to this company: 
        </div>
            <input 
                type="text" 
                value={new_user_email} 
                onChange={handleChange} 
                className="form-control"
                placeholder="Email"
            />
            <button onClick={() => set_wait_for_confirmation(true)} className="mt-2 btn btn-primary">Create and add user</button>
       </div>
   )
}
