import { AppIconButton } from "../../components";
import { useHistory } from "react-router-dom";

export default function Admin(){

    const history = useHistory();

    return (
        <div>
          <AppIconButton icon="arrowback" onClick={() => history.push("/")} />
            <a href="/admin/company_management" className="btn btn-primary mx-3">Manage Companies</a>
            <a href="/admin/site_management" className="btn btn-primary mx-3">ManageSites</a>
            <a href="/admin/exports" className="btn btn-primary mx-3">Exports</a>
        </div>

    )
}
