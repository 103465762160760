import clsx from 'clsx';
import { makeStyles, AppBar, Toolbar, Typography } from '@material-ui/core';
import AppIconButton from '../AppIconButton';
import { useCallback } from 'react';
import { useAppStore } from '../../store/AppStore';
import { api } from '../../api';
import { useMsal } from "@azure/msal-react";
import { ProfileContent } from "../ProfileContent"
import { getCompanyLocalStorage } from '../../utils/localStorage';

const useStyles = makeStyles((theme) => ({
  root: {
    //boxShadow: 'none',
    minWidth: '20rem',
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: "#074966"
  },
  logo: {
    height: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flexGrow: 1,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
}));

/**
 * Renders TopBar composition
 * @class TopBar
 */
const TopBar = ({ className, title = '', isAuthenticated, onMenu, onNotifications, ...restOfProps }) => {
  const classes = useStyles();
  const [state, dispatch] = useAppStore();
  const { instance } = useMsal();
  const handleOnLogout = useCallback(async () => {
    instance.logoutRedirect().catch(e => {
      console.error(e);
    });
    // dispatch({ type: 'LOG_OUT' });
  }, [dispatch]);

  return (
    <AppBar {...restOfProps} className={clsx(classes.root, className)} component="div">
      <Toolbar className={classes.toolbar} disableGutters>
        <div class="logo">
          CTACSub2
        </div>
        {/* <AppIconButton
          icon="logo"
          // color="primary"
          onClick={onMenu}
        /> */}

        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.buttons}>

          {isAuthenticated && (
            <div className="right_section">
              <p>Welcome {state.currentUser}</p>
              {getCompanyLocalStorage().company_name !== undefined &&
                <p className="ms-1">| Selected Company: {getCompanyLocalStorage().company_name}</p>
              }
              <AppIconButton icon="logout" title="Logout Current User" onClick={handleOnLogout} />
            </div>
          )}
        </div>

      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
