import { api } from '..';

const ENDPOINT = 'api/site/site-form';
const METHOD = 'countries()';

export async function countriesByAxios() {  
  try {
    const res = await api?.axios?.get(ENDPOINT, { params: { token: 1 } });
    const { data } = res;
    log.warn(`${METHOD} -`, data);
    return data;
  } catch (error) {
    log.error(`${METHOD} -`, error);
  }
  return undefined;
}

export default countriesByAxios;
