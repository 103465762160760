import { api } from '../';

async function get_companies() {
    try {
        const res = await api?.axios?.get('api/admin/companies');
        const { data } = res;
        log.warn(`get_companies -`, data);
        return data;
    } catch (error) {
        log.error(`get_companies -`, error);
    }
    return undefined;
}

async function get_all_users() {
    try {
        const res = await api?.axios?.get('api/admin/all_users/');
        const { data } = res;
        log.warn(`get_companies -`, data);
        return data;
    } catch (error) {
        log.error(`get_companies -`, error);
    }
    return undefined;
}

async function get_all_users_of_company(company_id) {
    try {
        const res = await api?.axios?.get(`api/admin/users/${company_id}`);
        const { data } = res;
        log.warn(`get_companies -`, data);
        return data;
    } catch (error) {
        log.error(`get_companies -`, error);
    }
    return undefined;
}

async function is_admin() {
    // Checks if the logged in uer is an admin
    try {
        const res = await api?.axios?.get('api/auth/check-admin');
        const { data } = res;
        log.warn(`get_companies -`, data);
        return data;
    } catch (error) {
        log.error(`get_companies -`, error);
    }
    return undefined;
}

async function post_company(payload) {
    try {
        const res = await api?.axios?.post('api/admin/company', {}, {params: payload});
        const { data } = res;
        log.warn(`get_companies -`, data);
        return data;
    } catch (error) {
        log.error(`get_companies -`, error);
    }
    return undefined;
}

async function add_user_to_company(user_id, company_id) {
    try {
        const res = await api?.axios?.post('api/admin/company/users', [user_id], {params: {company_id: company_id}});
        const { data } = res;
        log.warn(`get_companies -`, data);
        return data;
    } catch (error) {
        log.error(`get_companies -`, error);
    }
    return undefined;
}

async function create_user(email) {
    try {
        const res = await api?.axios?.post('api/admin/user', {}, {params: {email: email}});
        const { data } = res;
        log.warn(`get_companies -`, data);
        return data;
    } catch (error) {
        log.error(`get_companies -`, error);
    }
    return undefined;
}

async function remove_user_from_comapny(user_id, company_id) {
    try {
        const res = await api?.axios?.delete('api/admin/user', {params: {user_id: user_id, company_id: company_id}});
        const { data } = res;
        log.warn(`get_companies -`, data);
        return data;
    } catch (error) {
        log.error(`get_companies -`, error);
    }
    return undefined;
}

async function export_all_companies(year_id, as_zip, all_years) {

    if(as_zip===undefined){
        as_zip = false
    }
    if(all_years===undefined){
        all_years = false
    }

    try {
        const res = await api?.axios?.get('api/admin/export_all_companies',
            {params: {substance_id: 1, year_id: year_id, as_zip: as_zip, all_years: all_years},
            responseType: 'blob',
            timeout: 60000000,
            });
        return res
    } catch (error) {
        log.error(`get_companies -`, error);
    }
    return undefined;
}


async function export_company_status(year_id) {
  try {
    const res = await api?.axios?.get('api/admin/export_company_status', {
      params: { year_id: year_id, substance_id: 1,
      }, responseType: 'blob',
      timeout: 60000000,
    });
    return res;
  } catch (error) {
    log.error(` -`, error);
  }
  return undefined;
}

async function get_sites_of_company(company_id) {
  try {
    const res = await api?.axios?.get(`api/admin/sites/${company_id}`);
    return res.data;
  } catch (error) {
    log.error(` -`, error);
  }
  return undefined;
}

async function move_site(site_id, new_company_id) {
    try {
        const res = await api?.axios?.post('api/admin/move_site', {}, {params: {site_id: site_id, new_company_id: new_company_id}});
        const { data } = res;
        return data;
    } catch (error) {
    }
    return undefined;
}

async function rename_site(site_id, new_site_name) {
    try {
        const res = await api?.axios?.post('api/admin/rename_site', {}, {params: {site_id: site_id, new_site_name: new_site_name}});
        const { data } = res;
        return data;
    } catch (error) {
    }
    return undefined;
}

async function get_cached_export_files() {
    try {
        const res = await api?.axios?.get('api/admin/cached_export_files/');
        const { data } = res;
        return data;
    } catch (error) {
    }
    return undefined;
}

async function generate_export_all_companies(year_id, as_zip, all_years) {

    if(as_zip===undefined){
        as_zip = false
    }
    if(all_years===undefined){
        all_years = false
    }

    try {
        const res = await api?.axios?.post('api/admin/generate_export_all_companies',
            {},
            {params: {substance_id: 1, year_id: year_id, as_zip: as_zip, all_years: all_years},
            responseType: 'blob',
            });
        return res
    } catch (error) {
        log.error(`get_companies -`, error);
    }
    return undefined;
}

async function generate_aggregated_emissions_export(year_id, end_year_id) {
  try {
    const res = await api?.axios?.post('api/admin/genrate_aggregated_data_export', {}, {
        params: { year_id: year_id, end_year_id: end_year_id
      }, responseType: 'blob',
      timeout: 60000000,
    });
    return res;
  } catch (error) {
    log.error(` -`, error);
  }
  return undefined;
}

async function generate_export_company_status(year_id, end_year_id) {
  try {
    const res = await api?.axios?.post('api/admin/generate_company_status_export', {}, {
        params: { year_id: year_id, end_year_id: end_year_id, substance_id: 1,
      }, responseType: 'blob',
      timeout: 60000000,
    });
    return res;
  } catch (error) {
    log.error(` -`, error);
  }
  return undefined;
}


async function download_chached_export_file(file_name) {
    try {
        const res = await api?.axios?.get('api/admin/download_cached_export_file/', {
            params: { file_name: file_name,
      }, responseType: 'blob',
    });

        return res;
    } catch (error) {
    }
    return undefined;
}

async function delete_cached_export_files() {
    try {
        const res = await api?.axios?.post('api/admin/delete_cached_export_files');
        return res;
    } catch (error) {
    }
    return undefined;
}
export {
    get_companies,
    post_company,
    get_all_users,
    get_all_users_of_company,
    add_user_to_company,
    create_user,
    remove_user_from_comapny,
    is_admin,
    export_all_companies,
    export_company_status,
    get_sites_of_company,
    move_site,
    rename_site,
    get_cached_export_files,
    generate_export_all_companies,
    generate_aggregated_emissions_export,
    generate_export_company_status,
    download_chached_export_file,
    delete_cached_export_files,
}
