import { DialogActions, Button, DialogTitle, DialogContent, Dialog as MUIDialog } from '@material-ui/core';
import {Children, useState} from 'react';

export default function Dialog({
  children,
  title,
  open,
  onClose,
}){

  return (
    <MUIDialog open={open}>
      <DialogTitle id="form-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        {Children.map(children, (child, i) => child)}
      </DialogContent>
    <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
            Close
        </Button>
    </DialogActions>
    </MUIDialog>
  )
}
