import { Route, Switch } from 'react-router-dom';
import Admin from './admin';
import CompanyManagementView from './CompanyManagement';
import SiteManagementView from './SiteManagement';
import ExportView from './ExportView'


const AdminRoutes = () => {
  return (
    <Switch>
      <Route path="/admin" exact component={Admin} />
      <Route path="/admin/company_management" component={CompanyManagementView} />
      <Route path="/admin/exports" component={ExportView} />
      <Route path="/admin/site_management" component={SiteManagementView} />
    </Switch>
  );
};

export default AdminRoutes;
