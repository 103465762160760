import { Select, Button, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import Dialog from '../../dialogs/Dialog'
import {useState} from 'react';


export default function YearRangeDialog({years, api, title, text, open, onClose, defaultYear}){
    const [yearRangeDialogOpen, toggleYearRangeDialog] = useState(open)
    const [startYearID, setStartYearID] = useState(defaultYear)
    const [endYearID, setEndYearID] = useState(defaultYear)

    const generateExport = () => {
         api(startYearID, endYearID)
        toggleYearRangeDialog(false)
        onClose()
    }

    return (
        <Dialog
            title={title}
            open={yearRangeDialogOpen}
            onClose={() => {
                    toggleYearRangeDialog(false)
                    onClose()
                }
            }
        >
        <div>
            Please select a range of years for which you want to generate the export.
        </div>
        <div className="container mt-3">
            <div className="row">
                <div className="col">
                    <FormControl fullwidth className="w-100">
                        <InputLabel id="year-range-dialog__start-year__label">Start Year</InputLabel>
                        <Select
                            value={startYearID}
                            id="year-range-dialog__start-year"
                            labelId="year-range-dialog__start-year__label"
                            onChange={(e) => setStartYearID(e.target.value)}
                            label="Start Year"
                        >
                            {Object.entries(years).map(([year_id, year]) =>
                                <MenuItem value={year_id}> {year} </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
                <div className="col">
                    <FormControl className="w-100">
                        <InputLabel id="year-range-dialog__end-year__label">End Year</InputLabel>
                        <Select
                            value={endYearID}
                            id="year-range-dialog__end-year"
                            labelId="year-range-dialog__end-year__label"
                            onChange={(e) => setEndYearID(e.target.value)}
                            label="Start Year"
                        >
                            {Object.entries(years).map(([year_id, year]) =>
                                <MenuItem value={year_id}> {year} </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
        <Button onClick={generateExport} color="primary">
            Generate Export
        </Button>
      </Dialog>
    )
}
