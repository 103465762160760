import { api } from '../..';

const ENDPOINT = '/api/measurement/water-emission';
const METHOD = 'water()';

async function get_mean(measurement_id) {
    try {
        const res = await api?.axios?.get(`${ENDPOINT}/mean/${measurement_id}`);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function get_multiple(measurement_id) {
    try {
        const res = await api?.axios?.get(`${ENDPOINT}/multiple-values/${measurement_id}`);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function get_list(query_params) {
    try {
        const res = await api?.axios?.get(`${ENDPOINT}/list`, { params: query_params });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function get_measurement_methods() {
    try {
        const res = await api?.axios?.get(`${ENDPOINT}/measurement-methods`);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function get_sewage(query_params) {
    try {
        const res = await api?.axios?.get(`${ENDPOINT}/sewage-volume`, { params: query_params });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function post_sewage_volume(body_params) {
    try {
        const res = await api?.axios?.post(`${ENDPOINT}/sewage-volume`, body_params);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function post_measurement(body_params) {
    try {
        const res = await api?.axios?.post(`${ENDPOINT}/initialise-measurement`, body_params);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
        log.warn(body_params)
        log.warn(error.response)
    }
    return undefined;
}

async function patch_measurement_mean(body_params) {
    try {
        const res = await api?.axios?.patch(`${ENDPOINT}/mean`, body_params);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function patch_measurement_multiple(body_params) {
    try {
        const res = await api?.axios?.patch(`${ENDPOINT}/multiple-values`, body_params);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function delete_measurement(measurement_id) {
    try {
        const res = await api?.axios?.delete(`${ENDPOINT}/${measurement_id}`);
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function add_custom_measurement_method(query_params) {
    try {
        const res = await api?.axios?.post(`/api/water-measurement-method`, {}, { params: query_params });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

export { get_mean, get_multiple, get_list, get_measurement_methods, get_sewage, post_sewage_volume, post_measurement, patch_measurement_mean, patch_measurement_multiple, delete_measurement, add_custom_measurement_method };