import { api } from '../..';

const ENDPOINT = '/api/measurement/exposure';
const METHOD = 'exposure()';

async function get(measurement_id) {
    try {
        const res = await api?.axios?.get(`${ENDPOINT}/${measurement_id}`, { params: { token: 1 } });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function delete_measurement(measurement_id) {
    try {
        const res = await api?.axios?.delete(`${ENDPOINT}/${measurement_id}`, { params: { token: 1 } });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function post(body_params) {
    try {
        const res = await api?.axios?.post(`${ENDPOINT}`, body_params, { params: { token: 1 } });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function patch(measurement_id, body_params) {
    try {
        const res = await api?.axios?.patch(`${ENDPOINT}/${measurement_id}`, body_params, { params: { token: 1, measurement_id: measurement_id } });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
        log.info('Error Details:', error.response)
    }
    return undefined;
}

async function get_list(site_id, year_id, substance_id) {
    try {
        const res = await api?.axios?.get('/api/measurement/exposure-list', { params: { token: 1, site_id: site_id, year_id: year_id, substance_id: substance_id } });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function add_custom_measurement_method(query_params) {
    try {
        const res = await api?.axios?.post(`/api/exposure-measurement-method`, {}, { params: query_params });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function add_custom_respiratory_protection_type(query_params) {
    try {
        const res = await api?.axios?.post(`/api/exposure-respiratory-protection-type`, {}, { params: query_params });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

async function delete_measurement_task(measurement_id, task_id) {
    try {
        const res = await api?.axios?.delete(`${ENDPOINT}/measurement-task/${measurement_id}/${task_id}`, { params: { token: 1 } });
        const { data } = res;
        log.warn(`${METHOD} -`, data);
        return data;
    } catch (error) {
        log.error(`${METHOD} -`, error);
    }
    return undefined;
}

export { get, post, patch, get_list, delete_measurement, add_custom_measurement_method, add_custom_respiratory_protection_type, delete_measurement_task }