import { makeStyles, Grid } from '@material-ui/core';
import { formStyle } from '../../utils/styles';
import React from 'react';

export const useStyles = makeStyles((theme) => ({
  formBody: {
    ...formStyle(theme),
  },
}));

/**
 * Application styled Form container
 * @class AppForm
 */
const AppForm = ({ children, id, ...resOfProps }) => {
  const classes = useStyles();
  return (
    <form {...resOfProps} id={id}>
      <Grid container direction="column" alignItems="center">
        <Grid item className={classes.formBody}>
          {
            React.Children.map(
              children,
              (child, i) => {
                return React.cloneElement(child, {
                  form_id: id
                });
              })
          }
        </Grid>
      </Grid>
    </form>
  );
};

export default AppForm;