import { useIsAuthenticated } from "@azure/msal-react";

import { useEffect } from 'react';
import { useAppStore } from '../store';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import { api } from '../api';
import { isUserStillLoggedIn } from '../api/auth/utils';
import Authenticator from "./Authenticator";
import Debug_Authenticator from "./Debug_Authenticator";


/**
 * Renders Application routes depending on Logged or Anonymous users
 * @class Routes
 */
const Routes = () => {
  // const [state, dispatch] = useAppStore();

  // const isAuthenticatedMsal = useIsAuthenticated()

  // useEffect(() => {
  //   if (isAuthenticatedMsal) {
  //     dispatch({ type: 'LOG_IN' })
  //   } else {
  //     dispatch({ type: 'LOG_OUT' })
  //   }
  // }, [isAuthenticatedMsal])

  // useEffect(() => {

  //   // Check isn't token expired?
  //   const isLogged = isUserStillLoggedIn();
  //   //const isLogged = false;

  //   if (state.isAuthenticated && !isLogged) {
  //     // Token was expired, logout immediately!
  //     log.warn('Token was expired, logout immediately!');
  //     api?.auth?.logout();
  //     // dispatch({ type: 'LOG_OUT' }); // Not needed due to reloading App in api.auth.logout()
  //     return; // Thats all for now, the App will be completely re-rendered soon
  //   }

  //   if (isLogged && !state.isAuthenticated) {
  //     // Valid token is present but we are not logged in somehow, lets fix it
  //     log.warn('Token found, lets try to auto login');
  //     dispatch({ type: 'LOG_IN' });
  //   }
  // }, [state.isAuthenticated, dispatch]); // Effect for every state.isAuthenticated change actually

  // log.info('Routes() - isAuthenticated:', state.isAuthenticated);

  console.log(process.env)
  return process.env.REACT_APP_DEV_LOGIN_TOKEN === 'true' ? <Debug_Authenticator /> : <Authenticator />;
};

export default Routes;
