import AppIcon from "../AppIcon"
import { AppIconButton } from "../AppIconButton"
import { Collapse } from "@material-ui/core"
import { useState } from "react"


export default function Infobox(props) {
    let arrow
    if (props.arrow === undefined) {
        arrow = true
    } else {
        arrow = props.arrow
    }

    const [collapsed, setCollapse] = useState(false)
    return (
        <div className="infobox ">
            <AppIcon
                icon='help_outline'> </AppIcon>
            <div className="infobox_text ">
                <Collapse in={collapsed} collapsedSize={"20.5em"} timeout={2000}>
                    {props.children}
                </Collapse>
                {arrow &&
                    <AppIconButton onClick={() => setCollapse((collapsed) => !collapsed)}
                        icon='arrowdown' className={collapsed ? 'rotated' : ''} />
                }
            </div>
        </div>
    )
}