import { LinearProgress } from "@material-ui/core";
import { useState, useEffect, useCallback } from "react";
import useApi from "../../utils/useApi";
import api from '../../api'
import Selectrix from 'react-selectrix';
import { async } from "validate.js";
import { useStore } from "../../store";
import { setCompanyLocalStorage } from "../../utils/localStorage"
import { useHistory } from "react-router-dom";
import AdminButton from "../../components/AdminButton"
import { useMsal } from "@azure/msal-react";

export default function Landing() {

    let history = useHistory()

    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({})
    const { instance, accounts, inProgress } = useMsal();

    useEffect(() => {

        const get_companies_and_registered_state = async () => {
            let is_registered = await api.user.companies.is_registered()
            is_registered = is_registered.user_is_allowed
            if (is_registered) {
                setState({ companies: await api.user.companies.get(), is_registered: true })
            } else {
                setState({ companies: [], is_registered: false })
            }
            setLoading(false)
        }

        get_companies_and_registered_state()
    }, [])


    const [selected_company, set_selected_company] = useState({})


    useEffect(() => {
        set_selected_company({ key: state?.companies?.[0]?.id, label: state?.companies?.[0]?.name })
    }, [loading])

    if (loading) {
        return <LinearProgress />
    }

    const { companies, is_registered } = state

    if (!is_registered) {
        const body = `The account with%0D%0A%0D%0Aid: ${accounts[0]['homeAccountId']}%0D%0Aname: ${accounts[0]['name']}%0D%0Ausername: ${accounts[0]['username']}%0D%0A%0D%0Acould not be recognized.`
        return (
            <>Your account could not be recognized. Please write an {' '}
                <a href={`mailto: ctacsub2@ramboll.com?subject=CTACSub2%20Monitoring%20Tool%20Account%20not%20recognized.&body=${body.replace(' ', '%20')}`}>
                email
                </a> specifying the following information:<br/><br/>
                    id: {accounts[0]['homeAccountId']}<br/>
                    name: {accounts[0]['name']}<br/>
                    username: {accounts[0]['username']}
                </>
        )
    }


    const redirect = async () => {
        setCompanyLocalStorage(String(selected_company.key), selected_company.label)
        const sites = await api.sites.site.GetAllSites(selected_company.key)
        history.push('/site/overview')
        if (sites.length === 0) {
            history.push('/site/edit_site')
        } else {
            history.push('/site/overview')
        }
    }
    return (
        <div className="container landing_page">
            <div className="row justify-content-center">
                <div class="col-8 col">

                    <div className="container">
                        <p>
                            The data you enter can be used for two purposes: <br /><br />
                            <ul>
                                <li>
                                    Review report for CTACSub2: the data will be the basis of the CTACSub 2 consortium review report with the goal to extend the review period after September 2024.
                                </li>
                                <li>
                                    Report for upload to ECHA:
                                    <ul>
                                        <li>
                                            If you are covered by the current CTACSub application for the use of CrO3, you have to upload monitoring data annually to ECHA. This is necessary to comply with the authorisation decisions issued by the European Commission for the CTACSub application.
                                        </li>
                                        <li>
                                            After entering your monitoring data in the CTACSub2 monitoring tool you can export a report for upload to ECHA.
                                        </li>
                                        <li>
                                            Please note that the report created by the CTACSub2 monitoring tool is not submitted automatically to ECHA. Information on how to update your downstream user notifications and submit data from monitoring programmes can be found for example in section “j” of this FAQ by <a href="https://echa.europa.eu/support/qas-support/browse/-/qa/70Qx/view/scope/reach/authorisation">ECHA</a>.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                        <p>
                            Please check your report created by the CTACSub2 monitoring tool for completeness. Make sure you provided data in all sections and for all tasks relevant for you. In case you have any questions or comments, please email us via
                            In case you have any questions or comments, please send us an <a href="mailto: ctacsub2@ramboll.com?subject=Regarding the CTACsub2 monitoring tool">CATCSUB2@ramboll.com</a>.
                        </p>
                        <AdminButton/>
                        <p>
                            Please select the company you want to enter data for.
                        </p>
                    </div>

                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="row justify-content-center mb-2">
                                    <Selectrix
                                        multiple={false}
                                        materialize={false}
                                        onChange={(value) => set_selected_company(value)}
                                        defaultValue={String(selected_company.key)}
                                        options={companies.map((company) => ({ key: String(company.id), label: company.name }))}
                                    />
                                </div>
                                <div className="row justify-content-center">
                                    <div class="col text-center">
                                        <button className="btn btn-primary" onClick={redirect}>Choose Company</button>

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}
