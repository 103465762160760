import { useCallback, useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppLink from "../../components/AppLink";
import AppIconButton from "../../components/AppIconButton";
import AppButton from "../../components/AppButton";
import { api } from "../../api";
import SiteControl from "./SiteControl";
import { useAppStore } from "../../store";
import { getSiteLocalStorage, getYearLocalStorage, getCompanyLocalStorage, setSiteLocalStorage } from "../../utils/localStorage";
import useApi from "../../utils/useApi";
import { useHistory } from "react-router-dom";
import ExportReportDialog from './ExportReportDialog'
import Infobox from "../../components/InfoBox/InfoBox"
import AccordionInfobox from "../../components/InfoBox/AccordionInfobox";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
  },
}));


const Overview = () => {

  const [modalState, setModalState] = useState({ include_exposures: false, include_air_emissions: false, include_water_emissions: false, include_biomonitoring: false })

  const onDialogClose = (event, reason) => {
    log.info('DialogsSection.onDialogClose() - reason:', reason || 'buttonClick');
    setModalState({ modal: null });
  };

  const onEditEmailDialogOpen = () => {
    setModalState({
      modal: (
        <ExportReportDialog
          open
          email={modalState.email}
          onClose={onDialogClose}
          onConfirm={onDialogConfirm}
        />
      ),
    });
  };

  const onDialogConfirm = (data) => {
    log.info('onDialogConfirm() - data:', data);
    setModalState({ modal: null });
  };

  let history = useHistory()
  const [state, dispatch] = useAppStore();

  const get_sites = async () => {
    const sites = await api.sites.site.GetAllSites(getCompanyLocalStorage().company_id)
    if (sites.length === 0) {
      history.push('/site/edit_site')
    }
    if (!sites.map(site => site.id).includes(getSiteLocalStorage().site_id)) {

      dispatch({ type: 'SET_Site', selectedSiteId: sites[0].id, selectedSiteName: sites[0].name })
      setSiteLocalStorage(sites[0].id, sites[0].name)
    }
  }

  const [loading_sites, sites] = useApi(get_sites)


  const [loading, setLoading] = useState(true)
  const [loadingSubstances, setLoadingSubstances] = useState(false)


  const classes = useStyles();
  const [substances, setSubstances] = useState([]);
  const [status, setStatus] = useState({});
  const [options, setOptions] = useState()


  useEffect(() => {

    setLoadingSubstances(true)
    const getOptions = async () => {
      const select_options = await api.select_options.select_options.get()
      //setOptions(select_options)
      return select_options
    }
    const getAllSubStances = async () => {
      const apiResult = await api?.sites?.substance?.GetAll(getSiteLocalStorage().site_id, getYearLocalStorage().year_id);
      if (apiResult !== undefined) {
        //setSubstances(apiResult);
        return apiResult
      }
    };
    const getAllStatuse = async () => {
      const apiResultStatus = await api?.sites?.substance?.getStatus(getSiteLocalStorage().site_id, 1, getYearLocalStorage().year_id)
      if (apiResultStatus !== undefined) {
        return apiResultStatus
        // setStatus(status)
      }
      else {
        return { entry_status_exposures: 1, entry_status_water_emissions: 1, entry_status_air_emissions: 1, entry_status_biomonitoring: 1 }
      }
      // setStatus({exposure_status:0, water_emission_status:0, air_emission_status: 0, biomonitoring_status:0})
      // return ({exposure_status:1, water_emission_status:1, air_emission_status: 1, biomonitoring_status:1})
    }

    const getAllSubtancesStatuesesAndOptions = async () => {
      const select_options = await getOptions()
      const substances = await getAllSubStances()
      const status = await getAllStatuse()

      setOptions(select_options)
      setSubstances(substances)
      setStatus(status)
      setLoadingSubstances(false)
      setLoading(false)

    }

    getAllSubtancesStatuesesAndOptions();
    // getAllSubStances();
    // getAllStatuse();


  }, [loading_sites, state.selectedYearID, state.selectedSiteId]);

  if (loading || loading_sites) {
    return <LinearProgress />
  }

  const render_substances = () => {
    if (loadingSubstances) {
      return <CircularProgress className="mx-auto mt-5" />
    } else {
      return (
        <>
          <ul>

            {substances.map((substance) => (
              <li>
                <div className="substance_name">
                  <div >{substance.substance_name}</div>
                  <div><AppIconButton icon="edit" href="/site/substance" /></div>
                </div>
                <ul className="measurment-list">
                  <li>
                    <div className="measurement-type">Occupational / workplace exposure</div>
                    {/* <div className="status">{options.status[status.entry_status_exposures]}</div> */}
                    <div className="edit-button"><AppIconButton icon="edit" href="/measurement/exposure/overview" /></div>
                  </li>
                  <li>
                    <div className="measurement-type">Wastewater emissions</div>
                    {/* <div className="status">{options.status[status.entry_status_water_emissions]}</div> */}
                    <div className="edit-button"><AppIconButton icon="edit" href="/measurement/water/overview" /></div>
                  </li>
                  <li>
                    <div className="measurement-type">Air emission</div>
                    {/* <div className="status">{options.status[status.entry_status_air_emissions]}</div> */}
                    <div className="edit-button"><AppIconButton icon="edit" href="/measurement/air/overview" /></div>
                  </li>
                  <li>
                    <div className="measurement-type">Biomonitoring (recommended)</div>
                    {/* <div className="status">{options.status[status.entry_status_biomonitoring]}</div> */}
                    <div className="edit-button"><AppIconButton icon="edit" href="/measurement/biomonitoring/overview" /></div>
                  </li>
                </ul>
              </li>
            ))}
          </ul>
          {substances.length === 0 &&
            <AppIconButton
              icon="add"
              title="Add Substance"
              component={AppLink}
              to="/site/substance"
              className="link-primary"
            > Add a new substance</AppIconButton>

          }
        </>
      )
    }
  }

  return (
    <div className='site-overview_page'>
      <div className="h3">
        {`Substances for "${state.selectedSiteName}" in ${state.selectedYear}`}
      </div>
      {modalState.modal}
      <button label="Completeness Check" color="default" onClick={() => history.push('/site/completeness_check')} className="btn btn-primary mb-3 me-2"> Completeness Check
      </button>
      <button label="Export Report" color="default" onClick={onEditEmailDialogOpen} className="btn btn-primary mb-3"> Export Report
      </button>
      <Grid container >
        <Grid item sm={3}>
          <SiteControl></SiteControl>
        </Grid>
        <Grid item sm={6}>
          <Card >
            <CardContent>
              <Grid container direction="column">
                {render_substances()}
              </Grid>

            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={3} className="ps-3">
          <AccordionInfobox
            content={{
              'legal_entities_and_sites': {
                header: 'Legal Entities and Sites',
                'text': (<>
                  It is legally required to provide annual monitoring data for each legal entity and all sites using CrO3 that are covered and notified under the CTACsub authorisation.
                  <br/><br/>
                  If you have more than one legal entity, please do not report these as individual sites – you should have access to more than one company after you login.
                </>)
              },
              'how_to_enter_data': {
                header: 'How to enter data?',
                'text': (<>
                  Workplace exposure:
                  <br/><br/>
                  Enter all measurement values individually, as in a measurement report – do not split measurements if more than one task was measured. For example, enter the data as follows:
                  <br/><br/>
                  <ul>
                    <li>
                      Measurement 1: A personal measurement of task 1
                    </li>
                    <li>
                      Measurement 2: A static measurement of tasks 1 and 2, and
                    </li>
                    <li>
                      Measurement 3: A static measurement of task 2
                    </li>
                  </ul>
                  <br/><br/>
                  Air emissions, Wastewater emissions, and Biomonitoring:
                  <br/><br/>
                  The principle is similar. Therefore, the data entry is also similar for each of the sections. Smaller differences are described separately in each section.
                  <br/><br/>
                  In the wastewater section, it is also specified what should be done if no Cr/Cr(VI)-containing wastewater is emitted from your site but all liquid waste containing Cr/Cr(VI) is collected and disposed of separately (for example via an external contractor).
                </>)
              },
              'tips_and_recommondation': {
                header: 'Tips and recommendations',
                'text': (<>
                  <ul>
                    <li>
                      Add as many measurements for each year as you need.
                    </li>
                    <li>
                      The measurement report usually also provides an overview of important additional conditions.
                    </li>
                    <li>
                      You can pick up the data entry any time.
                    </li>
                    <li>
                      For a high-quality standard data, please fill all fields for each measurement.
                    </li>
                  </ul>
                </>)
              },
              'data_export_for_submission_to_echa': {
                header: 'Data Export for submission to ECHA',
                'text': (<>
                  Data on "Occupational / workplace exposure", "Air emissions" and "Wastewater emissions" is needed if you'd like to submit the printout to ECHA to comply with the authorisation conditions. Additional information on wastewater is given in the respective section.
                  <br/><br/>
                  Please note that it is also your responsibility to upload the report to ECHA.
                </>)
              },
              'legal': {
                header: 'Legal',
                'text': (<>
                  Ramboll will ensure that any data entered is handled in accordance with the requirements of national and EU law, in particular data protection law and competition law. To learn more about how Ramboll handles personal data, please see our Privacy Policy (<a href="https://ramboll.com/terms-of-use-and-privacy-policy/privacy-policy-digital-campaigns">https://ramboll.com/terms-of-use-and-privacy-policy/privacy-policy-digital-campaigns</a>).
                </>)
              },
            }}
          />
        </Grid>
      </Grid>

    </div >
  );
};

export default Overview;
