import api from '../api'

import { useState, useEffect, useCallback } from "react";


export default function AdminButton(){
    const [is_admin, set_is_admin] = useState(false) 

    useEffect(() => {
        const fetch_is_admin = async() => {
            const is_admin = await api.admin_panel.admin_panel.is_admin()
            if (is_admin !== undefined){
                set_is_admin(is_admin)
            }
        }
        fetch_is_admin()
    }, [])

    if (is_admin){
        return <a className="btn btn-primary" href="/admin">Go to admin panel</a>
    }else{
        return <></>
    }
}
