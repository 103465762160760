import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  LinearProgress,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";
import Selectrix from 'react-selectrix';
import {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";

import {api} from '../../api';
import AddCompany from './AddCompany'
import CompanyControlPanel from './CompanyControlPanel'
import { AppButton, AppIcon, AppIconButton } from "../../components";

export default function CompanyMangementView(){

    const [loading, setLoading] = useState(true)
    const [companies, setCompanies] = useState([])
    const [selected_company, setSelectedCompany] = useState({})
    const history = useHistory();

    useEffect(()=> {
         const fetchCompanies = async() => {
            const companies = await api.admin_panel.admin_panel.get_companies()
            if (companies !== undefined){
                setCompanies(companies)
                setLoading(false)
            }
         }
         fetchCompanies()
    }, [])

    const render_company_control_panel = (selected_company) => {
        if (selected_company?.key === undefined) {
            return (<></>)
        }
        return (
                <>
                <div className="row mt-5">
                    <div className="col">
                        <div className="h5">
                           Manage users for: {selected_company.label}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <CompanyControlPanel selected_company={selected_company} />
                    </div>
                </div>
            </>
        )
    }

    const handleSelect = (value) => {
        setSelectedCompany(value)
    }

    if (loading){
        return <LinearProgress />
    }

    const add_new_company = (company_id, company_name) => {
        const updated_companies = companies.concat({id: company_id, name: company_name})
        setCompanies(updated_companies)
        setSelectedCompany({key: company_id, label: company_name})
    }

    return (
        <div>
          <AppIconButton icon="arrowback" onClick={() => history.push("/admin")} />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="h1">Admin Panel</div>
                        <blockquote className="blockquote fst-italic"> "With great power comes great responsibility" - Uncle Ben</blockquote>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="h4">Create a new Company</div>
                        <AddCompany add_new_company={add_new_company} />
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col">
                        <div className="h4">Manage user access to company</div>
                        <div>Select the Company for which you want to add/remove users </div>
                        <Selectrix
                            materialize={false}
                            onChange={(value) => handleSelect(value)}
                            defaultValue={selected_company?.key}
                            options={companies}
                            customKeys={{key: "id", label:'name'}}
                        />
                    </div>
                </div>
                {render_company_control_panel(selected_company)}
            </div>
        </div>

    )
}
