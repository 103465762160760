import axiosInstance from './axios';
// import firebaseInstance from './firebase';
import * as auth from './auth';
import * as sites from './sites';
import * as measurements from './measurements';
import * as select_options from './select_options'
import * as user from './user'
import * as admin_panel from './admin_panel'

const api = {
  // Pre-configured HTTP request instances
  axios: axiosInstance,
  // firebase: firebaseInstance, // use FireBase if needed
  // fetch: fetch, // use custom fetch is needed

  // Properties
  //token: () => loadToken(),
  get url() {
    log.warn('Base URL ' + axiosInstance?.defaults?.baseURL)
    return axiosInstance?.defaults?.baseURL;
  },

  // API "modules"
  auth,
  sites,
  measurements,
  select_options,
  user,
  admin_panel
};

export default api;
