/**
 * Smartly reads value from localStorage
 */
export function localStorageGet(name, defaultValue = '') {
  const valueFromStore = localStorage.getItem(name);
  if (valueFromStore === null) return defaultValue; // No value in store, return default one

  try {
    const jsonParsed = JSON.parse(valueFromStore);
    if (['boolean', 'number', 'bigint', 'string', 'object'].includes(typeof jsonParsed)) {
      return jsonParsed; // We successfully parse JS value from the store
    }
  } catch (error) { }

  // console.log(`localStorageGet(${name}) - result:`, valueFromStore)
  return valueFromStore; // Return string value as it is
}

/**
 * Smartly writes value into localStorage
 */
export function localStorageSet(name, value) {
  let valueAsString;
  if (typeof value === 'object') {
    valueAsString = JSON.stringify(value);
  } else {
    valueAsString = String(value);
  }

  localStorage.setItem(name, valueAsString);
  // console.log(`localStorageSet(${name}, ${valueAsString})`);
}

/**
 * Deletes value by name from localStorage, if specified name is empty the localStorage is cleared.
 */
export function localStorageDelete(name) {
  // console.log(`localStorageDelete(${name})`);
  if (name) {
    localStorage.removeItem(name);
  } else {
    localStorage.clear();
  }
}

export function setSiteLocalStorage(site_id, site_name) {
  localStorageSet('site_id', site_id)
  localStorageSet('site_name', site_name)
}

export function getSiteLocalStorage() {
  const site_id = localStorageGet('site_id', undefined)
  const site_name = localStorageGet('site_name', undefined)
  return { site_id: site_id, site_name: site_name }
}

export function setYearLocalStorage(year_id, year_name) {
  localStorageSet('year_id', year_id)
  localStorageSet('year_name', year_name)
}

export function getYearLocalStorage() {
  const year_id = localStorageGet('year_id', 1)
  const year_name = localStorageGet('year_name', 2018)
  return { year_id: year_id, year_name: year_name }
}

export function setCompanyLocalStorage(company_id, company_name) {
  localStorageSet('company_id', company_id)
  localStorageSet('company_name', company_name)
}

export function getCompanyLocalStorage() {
  const company_id = localStorageGet('company_id', undefined)
  const company_name = localStorageGet('company_name', undefined)
  return { company_id: company_id, company_name: company_name }
}