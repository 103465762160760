import {useEffect, useState} from 'react';
import {api} from '../../api';
import ConfirmationDialog from '../../utils/ConfirmationDialog'

export default function AddCompany({add_new_company}){

    const [new_company_name, set_new_company_name] = useState("")
    const [wait_for_confirmatation, set_wait_for_confirmation] = useState(false)
    const handleChange = (e) => {
        set_new_company_name(e.currentTarget.value)
    }

    const post_company = async() => {
        if (new_company_name !== ""){
            const new_company_id = await api.admin_panel.admin_panel.post_company({company_name: new_company_name})
            if (new_company_id !== undefined){
                add_new_company(new_company_id, new_company_name)
                set_new_company_name("")
                set_wait_for_confirmation(false)
            }
        }
    }

    const render_confirmation = () => {
        if (wait_for_confirmatation){
            return <ConfirmationDialog 
                text={`Create company with name "${new_company_name}"?`}
                positive={'Create Company'}
                negative={'Cancel'}
                handleAnswer={(answer) => answer ? post_company() : set_wait_for_confirmation(false)}
            />
        }
        return <></>
    }

   return (
       <div> 
            {render_confirmation()}
            <input 
                type="text" 
                value={new_company_name} 
                onChange={handleChange} 
                placeholder="Company Name"
                className="me-2 form-control mb-1"
            />
            <button onClick={() => set_wait_for_confirmation(true)} className="btn btn-primary">Create Company</button>
       </div>
   )
}
