import { Route, Switch } from 'react-router-dom';
import { NotFound, Sites, MeasurementForms } from '../views';
import Landing from '../views/Sites/Landing';
import Overview from '../views/Sites/Overview'
import { PrivateLayout } from './Layout';
import Legal from '../views/Legal';
import AdminRoutes from '../views/admin/';

/**
 * List of routes available only for authenticated users
 * Also renders the "Private Layout" composition
 * @class PrivateRoutes
 */
const PrivateRoutes = () => {
  return (
    <PrivateLayout>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/site" component={Sites} />
        <Route path="/measurement" component={MeasurementForms} />
        <Route path="/legal" component={Legal} />
        <Route path="/admin" component={AdminRoutes} />
        {/* <Route path="/site/overview" component={Sites} />
        <Route path="/measurement/exposure" component={Exposure} />        */}
        {/* <Route component={NotFound} /> */}
      </Switch>
    </PrivateLayout>
  );
};

export default PrivateRoutes;
