import { AppStore } from "./store";
import { ErrorBoundary } from "./components";
import { AppRouter, Routes } from "./routes";

/**
 * Root Application Component
 * @class App
 */
const App = () => {
  return (
    <ErrorBoundary name="App">
      <AppStore>
        <AppRouter>
          <Routes />
        </AppRouter>
      </AppStore>
    </ErrorBoundary>
  );
};

export default App;
